import React, {useContext} from 'react';
import './HomePage.css';
import {Link, useNavigate} from "react-router-dom"; // Import your CSS file
import ImageSlider from './ImageSlider';
import Footer from './Footer';
import UserContext from "./UserContext";

function HomePage() {

    const { user } = useContext(UserContext);

    const navigate = useNavigate();

    const handleBookNowStandard = () => {
        if (user != null) {
            navigate('/booking/standard');
        } else {
            navigate('/login', { state: { from: '/booking/standard' } });
        }
    }

    const handleBookNowSpecial = () => {
        if (user != null) {
            navigate('/booking/special');
        } else {
            navigate('/login', { state: { from: '/booking/special' } });
        }
    }

    return (

        <div className="homepage">
            {/* Introduction */}
            <section className="home-section intro">
                <div className="container">
                    <h2>Welcome to Our Ride Booking Service</h2>

                </div>
                <div className="container">
                    <p>Book a ride with ease using our platform. We provide fast and reliable Limo transportation services. Our services are available for a variety of occasions, including airport transportation, weddings, birthdays, anniversaries, funerals, and graduations.</p>
                </div>
                <div className="container second">
                    <p>Whether you're heading to the airport, celebrating a special occasion, or simply need a reliable ride around town, GEDAB is here to cater to your needs. We prioritize your comfort, safety, and satisfaction, ensuring a seamless and enjoyable travel experience from start to finish.</p>
                </div>
            </section>

            {/*images slide*/}
            <section className="home-section image-slider">
                <div className="container">
                    <h2>Our Fleet</h2>
                </div>
                <ImageSlider />
            </section>
            {/* Booking Options */}
            <section className="home-section booking-options">
                <div className="container">

                        <h2>Book Your Ride</h2>
                </div>
                    <div className="booking-option">
                        <h3>Standard Booking</h3>
                        <p>Book a standard ride for everyday use.</p>
                        <button className="btn" onClick={handleBookNowStandard}>Book Now</button>
                        {/*<Link to="/booking/standard" className="btn" onClick={handleBookNowStandard}>Book Now</Link>*/}
                        {/*<a href="#" className="btn">Book Now</a>*/}
                    </div>
                    <div className="booking-option">
                        <h3>Special Occasion Booking</h3>
                        <p>Book a ride for special occasions or events.</p>
                        <button className="btn" onClick={handleBookNowSpecial}>Book Now</button>
                        {/*<Link to="/booking/special" className="btn" onClick={handleBookNowSpecial}>Book Now</Link>*/}
                        {/*<a href="#" className="btn">Book Now</a>*/}
                    </div>
            </section>

                {/* About Us */}
            <section className="home-section about">
                <div className="container">
                    <h2>About Us</h2>
                </div>
                <div className="container">
                    <p>Learn more about our company and the services we provide.</p>
                    <Link to="/about" className="btn readmore">Read More</Link>
                </div>
            </section>

            {/*footer*/}
            <Footer />
        </div>
    );
}

export default HomePage;
