import React from 'react';
import './AboutUs.css';
import Footer from "./Footer";
import {Link} from "react-router-dom";

function AboutUs() {
    return (
        <div>
            <div className="about-container">
                <div>
                    <h1>About Us</h1>
                    <p>Welcome to <span className="highlight">GEDAB RIDE</span>, where convenience meets reliability in the world of transportation.</p>
                </div>
                <div className="row">
                    <div className="column">
                        <div className="section">
                            <h2>Our Mission</h2>
                            <p>At <span className="highlight">GEDAB RIDE</span>, our mission is simple: to provide hassle-free ride booking services tailored to your needs.</p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="section">
                            <h2>What We Offer</h2>
                            <p>From quick trips around town to longer journeys, we've got you covered. With our easy-to-use platform, booking a ride has never been easier.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="column">
                        <div className="section">
                            <h2>Safety First</h2>
                            <p>Your safety is our top priority. Our team of drivers undergoes rigorous background checks, and our vehicles are regularly inspected to ensure your peace of mind.</p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="section">
                            <h2>Community Driven</h2>
                            <p>We're more than just a ride booking service – we're part of your community. We're proud to support local initiatives and give back to the neighborhoods we serve.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="column">
                        <div className="section">
                            <h2>Join Us</h2>
                            <p>Ready to experience the convenience of <span className="highlight">GEDAB RIDE</span>? Sign up now and let's start your journey together.</p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="section">
                            <h2>Feedback Welcome</h2>
                            <p>We value your feedback! Whether you have a question, suggestion, or just want to say hello,
                                we're here for you. Reach out to us <span className="highlight"><Link className="contactAbout" to="../contact">[Contact Information]</Link> </span>.</p>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    );
}

export default AboutUs;
