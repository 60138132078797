import React from 'react';
import './BookingCard.css'; // Import CSS for styling the booking card

const BookingCard = ({ booking, onClick }) => {
    return (
        <div className="booking-card" onClick={onClick}>
            <img src={booking.carImage} alt="Car" className="car-image-booked" />
            <div className="booking-details">
                <h3>Booking ID: <span className="bold">{booking.bookingId}</span></h3>
                <p><span className="bold">Date:</span> {booking.pickUpDate}</p>
                <p><span className="bold">Time:</span> {booking.pickUpTime}</p>
                <p><span className="bold">Pickup Location:</span> {booking.pickUpLocation}</p>
                <p><span className="bold">Dropoff Location:</span> {booking.dropOffLocation}</p>
                <p><span className="bold">Vehicle Type:</span> {booking.vehicleType}</p>
                <p><span className="bold">Booking Status:</span> {booking.status}</p>
                <div className="booking-actions">
                    <button>Edit</button>
                    <button>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default BookingCard;
