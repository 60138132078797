import React, { useContext, useEffect, useState } from 'react';
import './MyBooking.css';
import Footer from './Footer';
import BookingCard from './BookingCard'; // Import the BookingCard component
import UserContext from "./UserContext";
import PremiumSUV from '../images/car1.png'; // Import the image for the first car
import SUV from '../images/SUV.png'; // Import the image for the second car

const ManageBookingPage = () => {
    const [selectedBooking, setSelectedBooking] = useState(null);
    const [bookings, setBookings] = useState([]);
    const { user } = useContext(UserContext); // Get the user from UserContext

    useEffect(() => {
        const fetchBookings = async () => {
            try {
                //fetch('https://api.gedabride.com/api/login', {
                const response = await fetch(`https://api.gedabride.com/api/bookings/customer/${user.userId}`);
                if (response.ok) {
                    const data = await response.json();
                    setBookings(data);
                    //set the of bookings to the carImage
                    setBookings(data.map((booking, index) => ({
                        ...booking,
                        carImage: booking.vehicleType === 'SUV' ? SUV : PremiumSUV
                    })));
                    console.log('Bookings:', data);
                } else {
                    throw new Error('Failed to fetch bookings');
                }
            } catch (error) {
                console.error(error);
            }
        };

        if (user && user.userId) {
            fetchBookings();
        }
    }, [user]);

    return (
        <div>
            <div className="manage-booking-container">
                <h1>Manage Booking</h1>
                {selectedBooking ? (
                    <div className="booking-details-container">
                        <img src={selectedBooking.carImage} alt="Car" className="car-image-booked" />
                        <p><span className="bold">Booking ID:</span> {selectedBooking.bookingId}</p>
                        <p><span className="bold">Date:</span> {selectedBooking.pickUpDate}</p>
                        <p><span className="bold">Time:</span> {selectedBooking.pickUpTime}</p>
                        <p><span className="bold">Pickup Location:</span> {selectedBooking.pickUpLocation}</p>
                        <p><span className="bold">Dropoff Location:</span> {selectedBooking.dropOffLocation}</p>
                        <p><span className="bold">Vehicle Type:</span> {selectedBooking.vehicleType}</p>
                        <p><span className="bold">Status:</span> {selectedBooking.status}</p>
                        <div className="booking-actions">
                            <button>Edit</button>
                            <button>Cancel</button>
                        </div>
                        <button className="booking-actions-back" onClick={() => setSelectedBooking(null)}>Back to Bookings</button>
                    </div>
                ) : (
                    bookings.map((booking, index) => (
                        <BookingCard key={index} booking={booking} onClick={() => setSelectedBooking(booking)} />
                    ))
                )}
            </div>
            <div className="each-booking-footer">
                <Footer />
            </div>

        </div>
    );
};

export default ManageBookingPage;
