//successful booking page

import React from 'react';
import './BookingSuccessful.css';
import Footer from './Footer';
import { Link } from 'react-router-dom';

const BookingSuccessful = () => {
    return (
        <div className="booking-successful">
            <div className="booking-successful-content">
                <h1>Booking Successful</h1>
                <p>Your booking has been confirmed. Thank you for choosing GEDAB RIDE!</p>
                <Link to="/mybooking" className="btn">View Booking</Link>
            </div>
            <div className={"success-footer"}>
            <Footer />
            </div>
        </div>
    );
}

export default BookingSuccessful;