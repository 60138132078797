import React from 'react';
import { Link } from 'react-router-dom';
import './ServicePage.css';
import Footer from './Footer';
//import images
import airport from '../images/flight.png';
import weddings from '../images/wedding.png';
import birthdays from '../images/birthday.png';
import anniversaries from '../images/anniversary.png';
import funerals from '../images/funeral.png';
import graduations from '../images/graduation.png';

const ServicePage = () => {
    return (
        <div>
            <div className="service-page">
                <div className="service-intro">
                    <h1>Our Services</h1>
                    <p>Welcome to our services page! At <span className="highlight">GEDAB RIDE</span>, we strive to provide exceptional transportation services for a variety of occasions. Whether you're planning a special event or need reliable transportation for everyday needs, we've got you covered.</p>
                </div>
                <div className="service">
                    <div className="service1">
                        <h2>Airport Transportation</h2>
                        <img src={airport} alt="Airport" />
                        <p>Whether you're arriving or departing, our airport transportation service ensures a comfortable and hassle-free journey to or from the airport.</p>
                        <Link to="/booking/airport" className="btn book-now">Book Now</Link>
                    </div>
                </div>
                <div className="service">
                    <div className="service2">
                        <h2>Weddings</h2>
                        <img src={weddings} alt="Wedding" />
                        <p>Make your special day even more memorable with our wedding transportation service. Arrive in style and luxury.</p>
                        <Link to="/booking/weddings" className="btn book-now">Book Now</Link>
                    </div>
                </div>
                <div className="service">
                    <div className="service1">
                        <h2>Birthdays</h2>
                        <img src={birthdays} alt="Birthday" />
                        <p>Celebrate your birthday in style with our birthday transportation service. Enjoy a fun and comfortable ride to your party destination.</p>
                        <Link to="/booking/birthdays" className="btn book-now">Book Now</Link>
                    </div>
                </div>
                <div className="service">
                    <div className="service2">
                        <h2>Graduations</h2>
                        <img src={graduations} alt="Graduation" />
                        <p>Celebrate your graduation in style with our graduation transportation service. Enjoy a fun and comfortable ride to your party destination.</p>
                        <Link to="/booking/graduations" className="btn book-now">Book Now</Link>
                    </div>
                </div>
                <div className="service">
                    <div className="service1">
                        <h2>Anniversaries</h2>
                        <img src={anniversaries} alt="Anniversary" />
                        <p>Commemorate your special day with our anniversary transportation service. Enjoy a romantic and elegant journey with your loved one.</p>
                        <Link to="/booking/anniversaries" className="btn book-now">Book Now</Link>
                    </div>
                </div>
                <div className="service">
                    <div className="service2">
                        <h2>Funerals</h2>
                        <img src={funerals} alt="Funeral" />
                        <p>Our funeral transportation service provides respectful and dignified transportation for you and your loved ones during a difficult time.</p>
                        <Link to="/booking/funerals" className="btn book-now">Book Now</Link>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default ServicePage;
