import React from 'react';
import './Contact.css';
import Footer from './Footer';


const ContactPage = () => {
    return (
        <div>
        <div className="contact-container">
            <div className="request-form">
                {/* Include your request form JSX here */}
                <h3 className="contact-heading">Request Form</h3>
                {/* Example form fields */}
                <form>
                    <label htmlFor="name">Name <span className="required"> (Required)</span></label>
                    <input type="text" id="name" name="name" required />

                    <label htmlFor="email">Email <span className="required"> (Required)</span></label>
                    <input type="email" id="email" name="email" required />

                    <label htmlFor="phone">Phone <span className="required"> (Required)</span></label>
                    <input type="tel" id="phone" name="phone" required />

                    <label htmlFor="message">Message <span className="required"> (Required)</span></label>
                    <textarea id="message" name="message" required></textarea>

                    {/* Add a submit button */}
                    <button className="request-btn" type="submit">Submit</button>
                </form>
            </div>
            <div className="contact-details">
                <h2 className="contact-heading">Contact Us</h2>
                <div className="contact-card">
                    <p>Email: contact@gedabride.com</p>
                </div>
                <div className="contact-card">
                    <p>Phone: +1 (612) 513-0903</p>

                </div>
                <div className="contact-card">
                    <p>Phone: +1 (612) 461-8437</p>

                </div>

                {/*<div className="contact-card">*/}
                {/*    <p>Address: 123 Main Street, City, Country</p>*/}
                {/*</div>*/}
                <div className="contact-card">
                    <p>Follow us on <a href="https://twitter.com/gedabride" target="_blank" rel="noopener noreferrer">Twitter</a> for updates</p>
                </div>
            </div>
        </div>
        <Footer />

        </div>
    );
}

export default ContactPage;
