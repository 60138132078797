import React, { useContext, useState } from 'react';
import UserContext from './UserContext';
import { useNavigate } from 'react-router-dom';
import './Profile.css';
import Footer from './Footer';

const Profile = () => {
    const { user, updateUser, logoutUser } = useContext(UserContext);
    const history = useNavigate();

    // State variables for edit mode, edited values, and profile picture
    const [isEditing, setIsEditing] = useState(false);
    const [editedUser, setEditedUser] = useState({ ...user });

    const [formData, setFormData] = useState({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber
    });

    //user data read from the user context and set to the edited user
    // editedUser.firstName = user.firstName;
    // editedUser.lastName = user.lastName;
    // editedUser.email = user.email;
    // editedUser.phone = user.phone;

    // console.log('User from context:', user);
    // console.log('Edited user:', editedUser);
    // const [profilePicture, setProfilePicture] = useState(user.profilePicture);

    // Function to toggle edit mode
    const toggleEdit = () => {
        setIsEditing(!isEditing);
    };

    // Function to handle changes to input fields and send to the server localhost://8080/api/customers/update and also update the user context
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setEditedUser({ ...editedUser, [name]: value });
    }

    // Function to handle profile picture change
    // const handleProfilePictureChange = (e) => {
    //     const file = e.target.files[0];
    //     const reader = new FileReader();
    //     reader.onloadend = () => {
    //         setProfilePicture(reader.result);
    //     };
    //     if (file) {
    //         reader.readAsDataURL(file);
    //     }
    // };

    // Function to save changes
    const saveChanges = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`https://api.gedabride.com/api/customers/update`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                const updatedUser = await response.json();
                updateUser(updatedUser);
                alert('Profile updated successfully');
            } else {
                throw new Error('Failed to update profile');
            }
        } catch (error) {
            console.error('Error updating profile:', error);
            alert('Error updating profile');
        }
        // Update user data with edited values
        // updateUser(editedUser);
        // Update profile picture
        // setEditedUser({ ...editedUser, profilePicture: profilePicture });
        setIsEditing(false);
    };

    if (!user) {
        history('/');
        // return <div>Please log in</div>;
    }

    return (
        <div>
            <div className="profile-container">
                <div className="profile-card">
                    {/*<div className="profile-picture">*/}
                    {/*    <img src={profilePicture} alt="Profile" className="profile-image" />*/}
                    {/*    <div className="profile-field">*/}
                    {/*        {isEditing ? (*/}
                    {/*            <input type="file" accept="image/*" onChange={handleProfilePictureChange} />*/}
                    {/*        ) : (*/}
                    {/*            <span></span>*/}
                    {/*        )}*/}
                    {/*    </div>*/}
                    {/*    {isEditing ? (*/}
                    {/*        <input type="text" name="name" value={editedUser.name} onChange={handleChange} />*/}
                    {/*    ) : (*/}
                    {/*        <h2>{user.name}</h2>*/}
                    {/*    )}*/}
                    {/*</div>*/}
                    <h1>Profile</h1>
                    <div className="profile-details">
                        {/*first name*/}
                        <div className="profile-field">
                            <label className="bold">First Name:</label>
                            {isEditing ? (
                                <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} />
                            ) : (
                                <input type="text" name="firstName" value={user.firstName} disabled />
                            )}
                        </div>
                        {/*last name*/}
                        <div className="profile-field">
                            <label className="bold">Last Name:</label>
                            {isEditing ? (
                                <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} />
                            ) : (
                                <input type="text" name="lastName" value={user.lastName} disabled />
                            )}
                        </div>
                        <div className="profile-field">
                            <label className="bold">Email:</label>
                            <input type="text" name="email" value={user.email} disabled />
                            {/*{isEditing ? (*/}
                            {/*    <input type="text" name="email" value={editedUser.email} onChange={handleChange} />*/}
                            {/*) : (*/}
                            {/*    <input type="text" name="email" value={user.email} disabled />*/}
                            {/*)}*/}
                        </div>
                        <div className="profile-field">
                            <label className="bold">Phone Number:</label>
                            {isEditing ? (
                                <input type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} />
                            ) : (
                                <input type="text" name="phoneNumber" value={user.phoneNumber} disabled />
                            )}
                        </div>
                        {/*<div className="profile-field">*/}
                        {/*    <label className="bold">Address 1:</label>*/}
                        {/*    {isEditing ? (*/}
                        {/*        <input type="text" name="address_1" value={editedUser.address_1} onChange={handleChange} />*/}
                        {/*    ) : (*/}
                        {/*        <input type="text" name="address_1" value={user.address_1} disabled />*/}
                        {/*    )}*/}
                        {/*</div>*/}
                        {/*<div className="profile-field">*/}
                        {/*    <label className="bold">Address 2:</label>*/}
                        {/*    {isEditing ? (*/}
                        {/*        <input type="text" name="address_2" value={editedUser.address_2} onChange={handleChange} />*/}
                        {/*    ) : (*/}
                        {/*        <input type="text" name="address_2" value={user.address_2} disabled />*/}
                        {/*    )}*/}
                        {/*</div>*/}
                        {/*<div className="profile-field">*/}
                        {/*    <label className="bold">City:</label>*/}
                        {/*    {isEditing ? (*/}
                        {/*        <input type="text" name="city" value={editedUser.city} onChange={handleChange} />*/}
                        {/*    ) : (*/}
                        {/*        <input type="text" name="city" value={user.city} disabled />*/}
                        {/*    )}*/}
                        {/*</div>*/}
                        {/*<div className="profile-field">*/}
                        {/*    <label className="bold">State:</label>*/}
                        {/*    {isEditing ? (*/}
                        {/*        <input type="text" name="state" value={editedUser.state} onChange={handleChange} />*/}
                        {/*    ) : (*/}
                        {/*        <input type="text" name="state" value={user.state} disabled />*/}
                        {/*    )}*/}
                        {/*</div>*/}
                        {/*<div className="profile-field">*/}
                        {/*    <label className="bold">Zip:</label>*/}
                        {/*    {isEditing ? (*/}
                        {/*        <input type="text" name="zip" value={editedUser.zip} onChange={handleChange} />*/}
                        {/*    ) : (*/}
                        {/*        <input type="text" name="zip" value={user.zip} disabled />*/}
                        {/*    )}*/}
                        {/*</div>*/}
                        {/*in editing mode, no need to show logout*/}
                        {!isEditing && (
                            <div className="profile-actions">
                                <button onClick={toggleEdit}>Edit</button>
                                <button onClick={logoutUser}>Logout</button>
                            </div>
                        )}
                        {/*in editing mode, show save and cancel*/}
                        {isEditing && (
                            <div className="profile-actions">
                                <button onClick={saveChanges}>Save</button>
                                <button onClick={toggleEdit}>Cancel</button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="profile-footer">
                <Footer />
            </div>

        </div>
    );
};

export default Profile;
