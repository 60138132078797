import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ImageSlider.css';
import image1 from '../images/car1.png';
import image2 from '../images/car2.png';
// import image3 from '../images/car3.png';
import image4 from '../images/car4.png';
// import image5 from '../images/car5.jpg';
// import image6 from '../images/car6.png';

const ImageSlider = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 2000, // Adjust as needed
        slidesToShow: 1, // Display 3 images at a time
        slidesToScroll: 1,
        arrows: false, // Hide arrows
        autoplay: true,
        // autoplaySpeed: 3000, // Adjust as needed
        fade: true
    };

    return (
        <div className="slider-container">
            <Slider {...settings}>
                <div>
                    <img src={image1} alt="car 1" />
                </div>
                <div>
                    <img src={image2} alt="car 2" />
                </div>
                <div>
                    <img src={image4} alt="car 4" />
                </div>
                {/*<div>
                    <img src={image1} alt="Image 5" />
                </div>*/}
            </Slider>
        </div>
    );
}

export default ImageSlider;
