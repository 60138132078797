import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './LoginSignup.css';
import image from '../images/car2.png';
import Footer from './Footer';

const SignUpPage = () => {

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        password: ''
    });

    const navigate = useNavigate();
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        fetch('https://api.gedabride.com/api/signup', {
        // fetch('http://localhost:8080/api/signup', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
            .then((response) => {
                if (response.ok) {
                    navigate('/login');
                    console.log('Signup successful');
                } else if(response.status === 409){
                    setError('User already exists');
                }
            })
            .catch((error) => {
                console.error('Signup failed:', error.message);
            });
    }

    return (
        <div className="main-div">
            <div className="signup-page signup-page-div">
                <div className="signup-container">
                    <div className="signup-form">
                        <h2>Sign Up</h2>
                        {error && <p className="error-message">{error}</p>}
                        <form onSubmit={handleSubmit}>
                            <div className="input-group">
                                <label htmlFor="firstName">First Name</label>
                                <input type="text" id="firstName" name="firstName" value={formData.firstName} onChange={handleChange} required />
                                <label htmlFor="lastName">Last Name</label>
                                <input type="text" id="lastName" name="lastName" value={formData.lastName} onChange={handleChange} required />
                                <label htmlFor="phone">Phone</label>
                                <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} required />
                                <label htmlFor="email">Email</label>
                                <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
                                <label htmlFor="password">Password</label>
                                <input type="password" id="password" name="password" value={formData.password} onChange={handleChange} required />
                            </div>
                            <button type="submit" className="btn">Sign Up</button>
                        </form>
                        <p>Already have an account? <Link className="al" to="/login">Login</Link></p>
                    </div>
                    <div className="car-image">
                        <img src={image} alt="Car" />
                    </div>
                </div>
            </div>
            <Footer className="footer-login-signup" />
        </div>
    );
}

export default SignUpPage;
