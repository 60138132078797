import React, {useState, useEffect, useMemo} from 'react';
import { GoogleMap, DirectionsRenderer } from '@react-google-maps/api';
import './MapComponent.css';

const MapComponent = ({ pickUpLocation, dropOffLocation }) => {
    const [directions, setDirections] = useState(null);
    const [distance, setDistance] = useState('');
    const [map, setMap] = useState(null);

    useEffect(() => {
        if (pickUpLocation && dropOffLocation) {
            console.log('PickUpLocation:', pickUpLocation);
            console.log('DropOffLocation:', dropOffLocation);

            geocodeAddresses(pickUpLocation, dropOffLocation)
                .then(({ pickUpCoordinates, dropOffCoordinates }) => {
                    console.log('PickUpCoordinates:', pickUpCoordinates);
                    console.log('DropOffCoordinates:', dropOffCoordinates);
                    calculateDirections(pickUpCoordinates, dropOffCoordinates);
                })
                .catch((error) => {
                    console.error('Error in geocoding:', error);
                });
        }
    }, [pickUpLocation, dropOffLocation]);

    const geocodeAddress = (address) => {
        return new Promise((resolve, reject) => {
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ address: address }, (results, status) => {
                if (status === 'OK') {
                    const location = results[0].geometry.location;
                    resolve(location);
                } else {
                    reject(`Geocode was not successful for the following reason: ${status}`);
                }
            });
        });
    };

    const geocodeAddresses = async (pickUpLocation, dropOffLocation) => {
        try {
            const pickUpCoordinates = await geocodeAddress(pickUpLocation);
            const dropOffCoordinates = await geocodeAddress(dropOffLocation);
            return { pickUpCoordinates, dropOffCoordinates };
        } catch (error) {
            throw new Error(error);
        }
    };

    const calculateDirections = (pickUpCoordinates, dropOffCoordinates) => {
        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route(
            {
                origin: pickUpCoordinates,
                destination: dropOffCoordinates,
                travelMode: 'DRIVING',
            },
            (result, status) => {
                if (status === 'OK') {
                    setDirections(result);
                    const distanceInMeters = result.routes[0].legs[0].distance.value;
                    const distanceInMiles = (distanceInMeters / 1609.34).toFixed(2);
                    setDistance(distanceInMiles);
                } else {
                    console.error('Directions request failed due to ' + status);
                }
            }
        );
    };

    const memoizedDirections = useMemo(() => directions, [directions]);

    return (
        <div className="map-container">
            <p>Distance: {distance} miles</p>
            <GoogleMap
                center={{ lat: 0, lng: 0 }}
                zoom={8}
                mapContainerStyle={{ width: '100%', height: '300px' }}
                onLoad={(map) => setMap(map)}
            >
                {memoizedDirections && (
                    <DirectionsRenderer
                        directions={memoizedDirections}
                        options={{
                            polylineOptions: {
                                strokeColor: 'red',
                            },
                        }}
                    />
                )}
            </GoogleMap>
        </div>
    );
};

export default MapComponent;
