import React, { createContext, useState, useEffect } from 'react';

// Create UserContext
const UserContext = createContext();


export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    const loginUser = (userData) => {
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('isLoggedIn', 'true');
    };

    const logoutUser = () => {
        setUser(null);
        //redirect to login page
        localStorage.removeItem('user');
        localStorage.removeItem('isLoggedIn');
        window.location.href = '/';
    };

    const updateUser = (updatedUser) => {
        setUser(updatedUser);
        localStorage.setItem('user', JSON.stringify(updatedUser));
    };

    useEffect(() => {
        const loggedIn = localStorage.getItem('isLoggedIn') === 'true';
        const storedUser = localStorage.getItem('user');
        if (loggedIn && storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []);

    return (
        <UserContext.Provider value={{ user, loginUser, logoutUser, updateUser }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserContext;
