import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './NotFound.css'; // Import your CSS file
import lostIcon from '../images/lost-icon.png'; // Import your image file

const NotFound = () => {
    const history = useNavigate();

    function handleClick() {
        history('/');
    }

    return (
        <div className="not-found-container">
            <img src={lostIcon} alt="Lost Icon" className="not-found-graphic" />
            <h1 className="not-found-heading">Oops! Page Not Found</h1>
            <p className="not-found-message">Sorry, but the page you are looking for could not be found.</p>
            <p className="not-found-suggestion">You can return to the <Link onClick={handleClick} to={'/'}>homepage</Link>.</p>
        </div>
    );
}

export default NotFound;
