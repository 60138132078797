import React, {useContext, useState, useEffect, useRef } from 'react';
import {useNavigate} from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import VehicleCard from "./VehicleCard";
import './BookingPage.css'; // Import the CSS file
import UserContext from './UserContext';
import suv from '../images/car1.png';
import premiumSuv from '../images/car2.png';
import MapComponent from "./MapComponent";
import Footer from "./Footer";


const BookingPage = () => {
    const [step, setStep] = useState(1);
    const [pickUpDate, setPickUpDate] = useState(null);
    const [pickUpTime, setPickUpTime] = useState(null);
    const [pickUpLocation, setPickupLocation] = useState('');
    const [dropOffLocation, setDropOffLocation] = useState('');
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [vehicleType, setVehicleType] = useState('');
    const [price, setPrice] = useState(0.0);
    const [additionalDetails, setAdditionalDetails] = useState('');
    //customer id
    // const [customerId, setCustomerId] = useState('');
    const [errors, setErrors] = useState({});

    //get the user context
    const { user } = useContext(UserContext);

    const navigate = useNavigate();

    const pickUpInputRef = useRef(null);
    const dropOffInputRef = useRef(null);

    useEffect(() => {
        if (pickUpInputRef.current) {
            const autocomplete = new window.google.maps.places.Autocomplete(pickUpInputRef.current);
            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                if (place && place.formatted_address) {
                    setPickupLocation(place.formatted_address);
                }
            });
        }

        if (dropOffInputRef.current) {
            const autocomplete = new window.google.maps.places.Autocomplete(dropOffInputRef.current);
            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                if (place && place.formatted_address) {
                    setDropOffLocation(place.formatted_address);
                }
            });
        }
    }, []);

    const handleNext = () => {
        let stepErrors = {};

        if (step === 1) {
            if (!pickUpDate) stepErrors.pickupDate = "Pick-Up Date is required";
            if (!pickUpTime) stepErrors.pickupTime = "Pick-Up Time is required";
            if (!pickUpLocation) stepErrors.pickupLocation = "Pick-Up Location is required";
            if (!dropOffLocation) stepErrors.dropOffLocation = "Drop-Off Location is required";

            if (Object.keys(stepErrors).length === 0) {
                setStep(2);
                setErrors({});
            } else {
                setErrors(stepErrors);
            }
        } else if (step === 2) {
            if (!selectedVehicle) {
                stepErrors.selectedVehicle = "Please select a vehicle";
                //calculate the total price
                setErrors(stepErrors);
            } else {
                setStep(3);
                //set the total price in double data type
                setVehicleType(selectedVehicle.type)
                if(selectedVehicle.type === 'SUV'){
                    setPrice(50.0);
                } else {
                    setPrice(70.0);
                }
                setErrors({});
            }
        }
    };

    const handleConfirm = () => {

        const formattedPickUpTime = pickUpTime ? pickUpTime.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}) : null;

        const finalBookingDetails = {
            pickUpDate,
            pickUpTime: formattedPickUpTime,
            pickUpLocation,
            dropOffLocation,
            vehicleType,
            price,
            additionalDetails,
            customer: {
                id: user.userId
            }
        };

        function confirmBooking(finalBookingDetails) {
            console.log(finalBookingDetails);
            // Send the booking details to the server 8080
            // fetch('http://localhost:8080/api/bookings', {
            fetch('https://api.gedabride.com/api/bookings', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(finalBookingDetails)
            })
                .then((response) => {
                    if (response.ok) {
                        console.log('Booking confirmed');
                    //    show confirmation message
                    //     alert('Booking confirmed');
                        navigate('/bookingsuccessful');

                    } else {
                        console.error('Booking failed');
                    }
                })
                .then((data) => {
                    console.log('Booking Data:', data);
                })
                .catch((error) => {
                    console.error('Booking failed:', error.message);
                });

        }

        confirmBooking(finalBookingDetails);
    };


    const vehicles = [
        { type: 'SUV', price: '$50', image: suv },
        { type: 'Premium SUV', price: '$70', image: premiumSuv }
    ];

    const handleVehicleSelect = (vehicle) => {
        setSelectedVehicle(vehicle);
    };

    const now = new Date();

    // Calculate the minimum selectable time
    const minSelectableTime = () => {
        const selectedDate = pickUpDate ? new Date(pickUpDate) : null;
        const today = new Date();

        if (selectedDate && selectedDate.toDateString() === today.toDateString()) {
            return today;
        }
        return new Date(today.setHours(0, 0, 0, 0));
    };

    return (
        <div>
        <div className="booking-page">
            {step === 1 && (
                <div className="step-container">
                    <h2>Step 1: Ride Info</h2>
                    <div>
                        <label>Pick-Up Date </label>
                        <DatePicker
                            selected={pickUpDate}
                            onChange={(date) => setPickUpDate(date)}
                            dateFormat="MM/dd/yyyy"
                            popperPlacement="bottom"
                            onClickOutside={() => setPickUpDate(null)}
                            onFocus={(e) => e.target.readOnly = true}
                            showPopperArrow={false}
                            className="date-picker"
                            placeholderText="MM/DD/YYYY"
                            minDate={now}
                            customInput={<input readOnly />}
                        />
                        {errors.pickupDate && <p className="error-message">{errors.pickupDate}</p>}
                    </div>
                    <div>
                        <label>Pick-Up Time </label>
                        <DatePicker
                            selected={pickUpTime}
                            onChange={(time) => setPickUpTime(time)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={1}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            popperPlacement="bottom"
                            onFocus={(e) => e.target.readOnly = true}
                            onClickOutside={() => setPickUpTime(null)}
                            showPopperArrow={false}
                            className="time-picker"
                            placeholderText="Select Time"
                            minTime={minSelectableTime()}
                            maxTime={new Date(now.setHours(23, 59, 59, 999))}
                            customInput={<input readOnly />}
                        />
                        {errors.pickUpTime && <p className="error-message">{errors.pickUpTime}</p>}
                    </div>
                    <div>
                        <label>Pick-Up Location</label>
                        <input
                            ref={pickUpInputRef}
                            value={pickUpLocation}
                            onChange ={(e) => setPickupLocation(e.target.value)}
                            placeholder="Pick-Up location"
                            className="react-select-container"
                        />
                        {errors.pickUpLocation && <p className="error-message">{errors.pickUpLocation}</p>}
                    </div>
                    <div>
                        <label>Drop-Off Location</label>
                        <input
                            ref={dropOffInputRef}
                            value={dropOffLocation}
                            onChange={(e) => setDropOffLocation(e.target.value)}
                            placeholder="Drop-Off location"
                            className="input-field"
                        />
                        {errors.dropOffLocation && <p className="error-message">{errors.dropOffLocation}</p>}
                    </div>
                    <button
                        onClick={handleNext}
                        className="button next"
                    >
                        Next
                    </button>
                    {/*a div to show map direction between pickup and dropoff along with distnace in miles from google map*/}
                    <div className="map-container">
                        <h2> </h2>
                        <MapComponent pickUpLocation={pickUpLocation} dropOffLocation={dropOffLocation} />
                    </div>



                </div>
            )}
            {step === 2 && (
                <div className="step-container">
                    <h2>Select Vehicle</h2>
                    <div className="vehicle-container">
                        {vehicles.map((vehicle, index) => (
                            <div
                                key={index}
                                className={`vehicle-card ${selectedVehicle === vehicle ? 'selected' : ''}`}
                                onClick={() => handleVehicleSelect(vehicle)}
                            >
                                <VehicleCard type={vehicle.type} price={vehicle.price} image={vehicle.image} />
                            </div>
                        ))}
                    </div>
                    {errors.selectedVehicle && <p className="error-message">{errors.selectedVehicle}</p>}
                    <div>
                    {/*    calculate price of the trip*/}
                        {selectedVehicle && <p>Selected Vehicle: {selectedVehicle.type}</p>}
                        <p>Price: {selectedVehicle?.price}</p>
                    </div>
                    <button
                        onClick={handleNext}
                        className="button next"
                    >
                        Next
                    </button>
                </div>
            )}
            {step === 3 && (
                <div className="step-container">
                    <h2>Final Details</h2>
                    <p>Pick-Up Date: {pickUpDate?.toLocaleDateString()}</p>
                    <p>Pick-Up Time: {pickUpTime?.toLocaleTimeString()}</p>
                    <p>Pick-Up Location: {pickUpLocation}</p>
                    <p>Drop-Off Location: {dropOffLocation}</p>
                    <p>Vehicle: {selectedVehicle?.type}</p>
                    <h3>Total Price: {price}</h3>
                    <textarea
                        placeholder="Additional Instructions for the Driver"
                        value={additionalDetails}
                        onChange={(e) => setAdditionalDetails(e.target.value)}
                        className="textarea"
                    />
                    <button onClick={handleConfirm} className="button confirm">Confirm Booking</button>
                </div>
            )}



        </div>
            {/*inline style footer at bottom*/}
            <div className="each-booking-footer">
                <Footer />
            </div>
        </div>

    );
};

export default BookingPage;

