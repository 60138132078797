import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import UserContext from './UserContext'; // Import the UserContext

function Navbar() {
    const { user, logoutUser } = useContext(UserContext); // Use the UserContext

    const [showMenu, setShowMenu] = useState(false);
    const menuRef = useRef(null);
    const menuButtonRef = useRef(null);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const handleMenuItemClick = () => {
        setShowMenu(false); // Hide the menu after clicking a menu item
    };

    useEffect(() => {
        const handleClick = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target) && !menuButtonRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    return (
        <nav className="navbar">
            <div className="container">
                <Link to="/" className="logo">GEDAB RIDE</Link>
                <ul ref={menuRef} className={`nav-links ${showMenu ? 'active' : ''}`}>
                    <li><Link to="/" onClick={handleMenuItemClick}>Home</Link></li>
                    {user && <li><Link to="/profile" onClick={handleMenuItemClick}>Profile</Link></li>}
                    <li><Link to="/services" onClick={handleMenuItemClick}>Services</Link></li>
                    {user && <li><Link to="/mybooking" onClick={handleMenuItemClick}>Manage Booking</Link></li>}
                    <li><Link to="/contact" onClick={handleMenuItemClick}>Contact</Link></li>
                    <li><Link to="/about" onClick={handleMenuItemClick}>About Us</Link></li>
                    {user && <li><Link to="/" onClick={logoutUser}>Logout</Link></li>}
                </ul>
                {!user &&
                    <div className="login-signup">
                        <Link to="/login" className="btn login">Login</Link>
                        <Link to="/signup" className="btn signup">Sign Up</Link>
                    </div>
                }
                <div ref={menuButtonRef}>
                    <button className="menu-icon" onClick={toggleMenu}>
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
