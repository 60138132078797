import React, { useState, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import UserContext from './UserContext';
import './LoginSignup.css';
import image1 from '../images/car1.png';
import Footer from './Footer';

const LoginPage = () => {
    const { loginUser } = useContext(UserContext); // Use the loginUser function from UserContext to set the user data in the context
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    }); // Set the initial state for the form data
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }; // Handle the form input change and update the form data state accordingly

    const from = location.state?.from || '/';

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch('https://api.gedabride.com/api/login', {
        // fetch('http://localhost:8080/api/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
            .then((response) => {
                if (response.ok) {
                    return response.json(); // Parse the JSON from the response
                } else {
                    // Handle non-200 responses
                    throw new Error('Incorrect email or password. Please try again.');
                }
            })
            .then((data) => {
                const userData = {
                    profilePicture: 'https://via.placeholder.com/150',
                    userId: data.id, // Assuming the response has an `id` field
                    firstName: data.firstName, // Assuming the response has a `firstName` field
                    lastName: data.lastName, // Assuming the response has a `lastName` field
                    email: data.email, // Assuming the response has an `email` field
                    phoneNumber: data.phoneNumber // Assuming the response has a `phoneNumber` field
                };
                loginUser(userData);

                // Print the response data from the API to the console

                // navigate('/');
                navigate(from);
            })
            .catch((error) => {
                // Display error message
                setError(error.message);
            });

    };

    return (
        <div className="main-div">
            <div className="login-page">
                <div className="login-container">
                    <div className="car-image">
                        <img src={image1} alt="Car" />
                    </div>
                    <div className="login-form">
                        <h2>Login</h2>
                        {error && <p className="error-message">{error}</p>}
                        <form onSubmit={handleSubmit}>
                            <div className="input-group">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                                <label htmlFor="password">Password</label>
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <button type="submit" className="btn">Login</button>
                        </form>
                        <p>Don't have an account? <Link className="al" to="/signup">Sign up</Link></p>
                    </div>
                </div>
            </div>
            <div className="footer-login-signup">
                <Footer />
            </div>
        </div>
    );
};

export default LoginPage;
