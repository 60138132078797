import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <footer className="footer">
            <p>&copy; {new Date().getFullYear()} GEDAB RIDE. All rights reserved.
                <span className="space">US DOT XXXXXXX</span> <span className="space">
                MN DOT XXXXXXX</span></p>
        </footer>
    );
}

export default Footer;
