import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/HomePage';
import About from './components/AboutUs';
import NotFound from './components/NotFoundPage';
import MyBooking from './components/MyBooking';
import Contact from './components/Contact';
import Services from './components/ServicePage';
import Login from './components/Login';
import Signup from './components/Signup';
import BookingPage from './components/BookingPage';
import Profile from './components/Profile';
import BookingSuccessful from './components/BookingSuccessful';
import { UserProvider } from './components/UserContext'; // Import UserProvider

function App() {
    return (
        <UserProvider>
            <Router>
                <div className="App">
                    <Navbar />
                    <div className="content">
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/contact" element={<Contact />} />
                            <Route path="/services" element={<Services />} />
                            <Route path="/mybooking" element={<MyBooking />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/signup" element={<Signup />} />
                            <Route path="/booking/:service" element={<BookingPage />} />
                            <Route path="/profile" element={<Profile />} />
                            <Route path="/bookingsuccessful" element={<BookingSuccessful />} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </div>
                    {/*<Footer />*/}
                </div>
            </Router>
        </UserProvider>
    );
}

export default App;
