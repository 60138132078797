import React from 'react';

const VehicleCard = ({ type, price, image }) => {
    return (
        <div className="vehicle-card">
            <img src={image} alt={`${type} car`} />
            <h3>{type}</h3>
            <p>Price: {price}</p>
        </div>
    );
};

export default VehicleCard;
